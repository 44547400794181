<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Send Slot Gift</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Bonus Chip"
                  v-model="slotGiftModel.bonusChip"
                  type="number"
                ></v-text-field>
                <h3>{{ slotGiftModel.bonusChip | formatMoney }}</h3>
              </v-col>
            </v-row>
            <div class="row">
              <v-col cols="12" sm="1"
                ><v-btn
                  justify="space-around"
                  color="green"
                  dark
                  class="mb-2 mr-2"
                  @click="slotGiftModel.bonusChip += 5000000000"
                  >5B</v-btn
                >
              </v-col>
              <v-col cols="12" sm="1"
                ><v-btn
                  justify="space-around"
                  color="green"
                  dark
                  class="mb-2 mr-2"
                  @click="slotGiftModel.bonusChip += 10000000000"
                  >10B</v-btn
                >
              </v-col>
              <v-col cols="12" sm="1"
                ><v-btn
                  justify="space-around"
                  color="green"
                  dark
                  class="mb-2 mr-2"
                  @click="slotGiftModel.bonusChip += 25000000000"
                  >25B</v-btn
                >
              </v-col>
              <v-col cols="12" sm="1"
                ><v-btn
                  justify="space-around"
                  color="green"
                  dark
                  class="mb-2 mr-2"
                  @click="slotGiftModel.bonusChip += 50000000000"
                  >50B</v-btn
                >
              </v-col>
              <v-col cols="12" sm="1"
                ><v-btn
                  justify="space-around"
                  color="green"
                  dark
                  class="mb-2 mr-2"
                  @click="slotGiftModel.bonusChip += 100000000000"
                  >100B</v-btn
                >
              </v-col>
              <v-col cols="12" sm="1"
                ><v-btn
                  justify="space-around"
                  color="green"
                  dark
                  class="mb-2 mr-2"
                  @click="slotGiftModel.bonusChip += 250000000000"
                  >250B</v-btn
                >
              </v-col>
              <v-col cols="12" sm="1"
                ><v-btn
                  justify="space-around"
                  color="green"
                  dark
                  class="mb-2 mr-2"
                  @click="slotGiftModel.bonusChip += 500000000000"
                  >500B</v-btn
                >
              </v-col>
              <v-col cols="12" sm="1"
                ><v-btn
                  justify="space-around"
                  color="green"
                  dark
                  class="mb-2 mr-2"
                  @click="slotGiftModel.bonusChip += 1000000000000"
                  >1T</v-btn
                >
              </v-col>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="slotGiftModel.bonusChip = 0"
            >Clear</v-btn
          >

          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="sendSlotGift"
            >Send Bonus</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import notificationType from "@/lib/notificationType";
export default {
  data() {
    return {
      slotGiftModel: {
        bonusChip: 0,
      },
      dialog: false,
    };
  },
  watch: {
    async clicked() {
      this.dialog = true;
    },
  },
  props: {
    clicked: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
    },
    lang: {
      type: String,
    },
    userName: {
      type: String,
    },
    notificationToken: {
      type: String,
    },
  },
  methods: {
    async sendSlotGift() {
      this.slotGiftModel.userId = this.userId;
      this.slotGiftModel.bonusChip = parseInt(this.slotGiftModel.bonusChip);
      await this.$store.dispatch("players/sendSlotGift", this.slotGiftModel);
      if (this.notificationToken)
        await this.$store.dispatch("notifications/sendNotification", {
          title: this.$t("lotteryTitle", this.lang),
          data: { type: notificationType.LOTTERY_BONUS },
          body: this.$t("lotteryMessage", this.lang, {
            name: this.userName,
            chip: window.slot.string.formatMoney(this.slotGiftModel.bonusChip),
          }),
          registrationToken: [this.notificationToken],
        });
      this.dialog = false;
    },
    mounted() {
      this.slotGiftModel.bonusChip = 0;
    },
  },
};
</script>
