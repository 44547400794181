<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>
        Bot
        <span class="red--text">
          {{ this.$store.state.players.all.botCount | formatMoney }}
        </span>
      </v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-toolbar-title>
        Player
        <span class="yellow--text">
          {{ this.$store.state.players.all.playerCount | formatMoney }}
        </span>
      </v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-toolbar-title>
        Total
        <span class="green--text">
          {{ this.$store.state.players.all.totalItems | formatMoney }}
        </span>
      </v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-toolbar-title>
        Sit
        <span class="purple--text">
          {{ this.$store.state.players.all.sitCount | formatMoney }}
        </span>
      </v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>

      <template v-if="$store.state.players.all.deviceGroup">
        <v-toolbar-title style="text-align: center;">
          <v-img
            max-height="28"
            max-width="28"
            min-height="28"
            min-width="28"
            :src="`https://bundle.slotbase.net/assets/img/android.png`"
            @click="
              os = os == 'android' ? '' : 'android';
              initialize();
            "
            :style="`float:left;opacity:${'android' == os || !os ? 1 : 0.5}`"
          />
          <span
            v-if="
              $store.state.players.all.deviceGroup.find(
                (m) => m.key == 'android'
              )
            "
            class="yellow--text"
          >
            {{
              $store.state.players.all.deviceGroup.find(
                (m) => m.key == "android"
              ).total
            }}
          </span>
        </v-toolbar-title>
        <v-toolbar-title style="text-align: center;">
          <v-img
            max-height="28"
            max-width="28"
            min-height="28"
            min-width="28"
            :src="`https://bundle.slotbase.net/assets/img/ios.png`"
            @click="
              os = os == 'ios' ? '' : 'ios';
              initialize();
            "
            :style="
              `margin-left:10px;float:left;opacity:${
                'ios' == os || !os ? 1 : 0.5
              }`
            "
          />
          <span
            v-if="
              $store.state.players.all.deviceGroup.find((m) => m.key == 'ios')
            "
            class="yellow--text"
          >
            {{
              $store.state.players.all.deviceGroup.find((m) => m.key == "ios")
                .total
            }}
          </span>
        </v-toolbar-title>

        <v-toolbar-title style="text-align: center;">
          <v-img
            max-height="28"
            max-width="28"
            min-height="28"
            min-width="28"
            :src="`https://bundle.slotbase.net/assets/img/webgl.png`"
            @click="
              os = os == 'webgl' ? '' : 'webgl';

              initialize();
            "
            :style="
              `margin-left:10px;float:left;opacity:${
                'webgl' == os || !os ? 1 : 0.5
              }`
            "
          />
          <span
            v-if="
              $store.state.players.all.deviceGroup.find((m) => m.key == 'webgl')
            "
            class="yellow--text"
            style="text-align: center;"
          >
            {{
              $store.state.players.all.deviceGroup.find((m) => m.key == "webgl")
                .total
            }}
          </span>
        </v-toolbar-title>
      </template>
    </v-toolbar>
    <v-toolbar flat>
      <v-toolbar-title>Players</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.players.select"
        :items="items"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Connect/Disconnect"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.players.playerSelect"
        :items="playerItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Bot Players/Real Players"
      ></v-combobox>
      <v-combobox
        v-model="$store.state.players.sitSelect"
        :items="sitItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Sit In/Sit Out"
      ></v-combobox>
      <v-combobox
        v-model="$store.state.players.platformSelect"
        :items="platformItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Platform"
      ></v-combobox>
      <v-combobox
        v-model="$store.state.players.pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Page"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn
        color="green"
        dark
        @click="$store.dispatch('players/getOverSitInData')"
        class="mb-2"
        >OverSitInData</v-btn
      >

      <!-- <v-btn color="red" dark @click="resetSitInData" class="mb-2"
        >Reset SitInData</v-btn
      >
      <v-btn color="blue" dark @click="addJackPot" class="mb-2"
        >AddJackPot</v-btn
      > -->
    </v-toolbar>
    <v-toolbar flat v-if="$store.state.players.all.deviceGroup">
      <v-divider class="mx-2" inset vertical></v-divider>
      <div
        v-for="(item, index) in _.sortBy(
          $store.state.players.all.locationGroup,
          'total'
        ).reverse()"
        :key="index"
        style="text-align: center;"
      >
        <v-tooltip bottom>
          <template
            :allow-overflow="true"
            v-slot:activator="{ on }"
            :dark="true"
            color="#033"
          >
            <div v-on="on">
              <v-img
                :src="
                  `https://bundle.slotbase.net/assets/flags/${item.key}.png`
                "
                @click="
                  flag = item.key == flag ? '' : item.key;
                  initialize();
                "
                :style="`opacity:${item.key == flag || !flag ? 1 : 0.5}`"
                max-height="32"
                max-width="32"
                min-height="32"
                min-width="32"
              />
              <span>{{ item.total }} </span>
            </div>
          </template>
          <span>{{ item.countryName }} </span>
          <div>
            Android:
            <span style="color:#f4ff49;">{{ item.android }}</span>
          </div>
          <div>
            Ios: <span style="color:#f4ff49;">{{ item.ios }}</span>
          </div>
          <div>
            WebGl: <span style="color:#f4ff49;">{{ item.webgl }}</span>
          </div>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :fixed-header="true"
      :items="$store.state.players.all.players"
      :page.sync="page"
      :options.sync="options"
      :server-items-length="$store.state.players.all.totalItems"
      hide-default-footer
      class="elevation-1"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:item.action="{ item }">
        <v-icon big class="mr-2" @click="disconnectItem(item)">link_off</v-icon>
        <v-icon big class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon big class="mr-2" @click="addMessage(item)">message</v-icon>
        <v-icon big class="mr-2" @click="sendSlotGiftDialog(item)">mood</v-icon>
      </template>

      <template v-slot:item.user.userName="{ item }">
        <UserDetailCard
          :userId="item.user.id"
          :pictureUrl="item.user.picture"
          :userName="item.user.userName"
          :level="item.user.level"
          :flag="item.user.locationInfo.countryCode"
        />
      </template>

      <template v-slot:item.user.isBot="{ item }">
        <v-tooltip bottom>
          <template
            :allow-overflow="true"
            v-slot:activator="{ on }"
            :dark="true"
            color="#033"
          >
            <v-img
              width="25"
              v-on="on"
              style="float:left"
              :src="
                `https://bundle.slotbase.net/assets/img/${item.user.deviceInfo.os}.png`
              "
            />
            <v-img
              width="25"
              v-on="on"
              :src="
                `https://bundle.slotbase.net/assets/img/${item.user.platformInfo.snId}.png`
              "
            />
          </template>

          <span>
            <json-viewer
              name="df"
              :value="item.user.deviceInfo"
              :expand-depth="5"
              theme="my-awesome-json-theme"
              sort
            ></json-viewer>
            <json-viewer
              name="df"
              :value="item.user.platformInfo"
              :expand-depth="5"
              theme="my-awesome-json-theme"
              sort
            ></json-viewer>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.fps="{ item }">
        <div v-bind:style="{ color: item.fps > 40 ? 'lime' : '#ff6d6d' }">
          {{ item.fps }}
        </div>
      </template>
      <template v-slot:item.version="{ item }">
        <div
          v-bind:style="{
            color:
              item.user.deviceInfo.gameVersion != currentVersion
                ? '#ff6d6d'
                : '',
          }"
        >
          {{ item.user.deviceInfo.gameVersion }}
        </div>
      </template>

      <template v-slot:item.bonusChip="{ item }">
        <v-tooltip bottom>
          <template
            :allow-overflow="true"
            v-slot:activator="{ on }"
            :dark="true"
            color="#033"
          >
            <span
              v-bind:style="{
                color: item.bonusChipsCount > 0 ? 'lime' : '#fff',
              }"
              v-on="on"
              >{{ item.bonusChipsCount | formatMoney }}</span
            >
            <span>
              &nbsp;/ {{ item.bonusChips.length }} /
              <span
                v-if="
                  item.bonusChips.filter(
                    (vendor) => vendor && vendor.bonusType.indexOf('Video') > -1
                  ).length > 0
                "
                style="color:#f4ff49;"
                >{{
                  item.bonusChips.filter(
                    (vendor) => vendor.bonusType.indexOf("Video") > -1
                  ).length
                }}</span
              >
            </span>
          </template>
          <div v-for="(bonus, index) in item.bonusChips" :key="index">
            {{ bonus.bonusType }}:
            <span style="color:lime;">{{ bonus.chip | formatMoney }}</span>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.chipAmount="{ item }">
        <div>{{ item.user.chipAmount | formatMoney }}</div>
        <div>{{ item.user.sitInData.chip | formatMoney }}</div>
      </template>

      <template v-slot:item.roomInfo.name="{ item }">
        <span v-if="item.roomInfo">
          {{ item.roomInfo.name }} / {{ item.roomInfo.roomId }} /
          {{ item.roomInfo.type }}
        </span>
      </template>
      <template v-slot:item.connectTime="{ item }">
        <div v-if="item.isDisconnect" style="color:yellow">
          D: {{ (120 + item.disconnectTime) | getDateFormat }}
        </div>
        <div>C: {{ item.connectTime | getDateFormat }}</div>
      </template>
      <template v-slot:item.thisTimeProfitOrLoss="{ item }">
        <div
          v-bind:style="{
            color: item.profitRoom > 0 ? 'lime' : '#FF6D6D',
          }"
        >
          pr:{{ item.profitRoom | formatMoney }}
        </div>
        <div
          v-bind:style="{ color: item.profitSession > 0 ? 'lime' : '#FF6D6D' }"
        >
          ps:{{ item.profitSession | formatMoney }}
        </div>
      </template>
      <template v-slot:item.user.locationInfo.countryName="{ item }">
        <div style="display: flex;">
          <v-avatar size="36" style="margin-right:5px; margin-top:3px;">
            <v-img
              :src="
                `https://bundle.slotbase.net/assets/flags/${item.user.locationInfo.countryCode.toLocaleLowerCase()}.png`
              "
            />
          </v-avatar>
          <div>
            {{ item.user.locationInfo.countryName }} -
            {{ item.user.locationInfo.continentName }}
            <div v-if="item.user.deviceInfo.ipAdress">
              {{ item.user.deviceInfo.ipAdress.split(",")[0] }}
            </div>
          </div>
        </div>
      </template>
      <!-- 1 guest
      2 fb
      3 email
      4 apple-->
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        @input="pageClick"
        :length="totalPage"
        :total-visible="20"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="Message"
                    v-model="messageModel.m"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Message Code"
                    placeholder="MESSAGE01"
                    v-model="messageModel.mc"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="sendMessage">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <SlotGiftCard
      v-if="curretItem.user"
      :clicked="dialog1"
      :userId="curretItem.user.id"
      :userName="curretItem.user.userName"
      :lang="curretItem.user.playerSettings.language.split('-')[0]"
      :notificationToken="curretItem.user.deviceInfo.notificationToken"
    />
  </div>
</template>
<script>
import router from "@/router";
import UserDetailCard from "@/components/Users/UserDetailCard";
import SlotGiftCard from "./components/SlotGiftCard";
export default {
  components: {
    UserDetailCard,
    // eslint-disable-next-line vue/no-unused-components
    SlotGiftCard,
    // eslint-disable-next-line vue/no-unused-components
  },
  data() {
    return {
      page: 1,
      dialog: false,
      dialog1: false,
      playerIOdialog: false,
      time: "",
      messageModel: {},
      search: "",
      options: {},
      flag: "",
      os: "",
      curretItem: {},
      select: { id: -1, value: "All" },
      playerSelect: { id: -1, value: "All" },
      sitSelect: { id: -1, value: "All" },
      pageItemSelect: { id: 50, value: "50" },
      pageCount: 0,
      currentPage: 0,
      itemsPerPage: 50,
      totalPage: 1,
      pagination: {
        sortBy: "name",
      },
      pageItems: [
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
        { id: 10000, value: "10000" },
      ],
      platformItems: [
        { id: -1, value: "All" },
        { id: 1, value: "GuestAndroid" },
        { id: 2, value: "GuestIos" },
        { id: 3, value: "Facebook" },
        { id: 4, value: "Apple" },
        { id: 5, value: "Mail" },
        { id: 6, value: "Gmail" },
        { id: 75, value: "Test" },
      ],
      items: [
        { id: -1, value: "All" },
        { id: false, value: "Connect" },
        { id: true, value: "Disconnect" },
      ],
      playerItems: [
        { id: -1, value: "All" },
        { id: true, value: "Bot Players" },
        { id: false, value: "Real Players" },
      ],
      sitItems: [
        { id: -1, value: "All" },
        { id: true, value: "Sit In" },
        { id: false, value: "Sit Out" },
      ],
      headers: [
        // { text: "Picture", value: "user.picture" },
        { text: "Name", value: "user.userName" },
        { text: "Level", value: "user.level" },
        { text: "Platform", value: "user.isBot" },
        { text: "Fps", value: "fps" },
        { text: "Room / Id / Type", value: "roomInfo.name" },
        { text: "SitInData.chip", value: "chipAmount" },
        { text: "ProfitOrLoss", value: "thisTimeProfitOrLoss" },
        { text: "Bonus Chip", value: "bonusChip" },
        { text: "ConnectTime", value: "connectTime" },
        { text: "LocationInfo", value: "user.locationInfo.countryName" },
        { text: "Version", value: "version" },
        { text: "Actions", value: "action", sortable: false },
      ],
      headersOutPlayer: [
        { text: "userId", value: "userId" },
        { text: "Platform", value: "os" },
        { text: "Fps", value: "fps" },
        { text: "connectChip", value: "connectChip" },
        { text: "connectTime", value: "connectTime" },
        { text: "profitOrLoss", value: "profitOrLoss" },
        { text: "Bonus Chip", value: "bonusRewards" },

        { text: "playTime", value: "playTime" },
      ],
    };
  },
  computed: {},
  watch: {
    options: {
      handler() {
        this.initializePage();
      },
      deep: true,
    },
  },
  methods: {
    // eslint-disable-next-line no-console
    pageClick(a) {
      this.currentPage = a;
      // this.initialize();
    },
    getColor(item) {
      if (item.disconnectChip > item.connectChip) return "lime";
      if (item.disconnectChip < item.connectChip) return "#FF6D6D";
    },
    getTimeAgo(sec) {
      let a = new Date();
      a.setSeconds(a.getSeconds() - sec);
      return a;
    },
    async initialize() {
      this.initializePage(1);
    },
    async resetSitInData() {
      await this.$store.dispatch("players/overSitInData");
    },
    async addJackPot() {
      await this.$store.dispatch("players/addJackPot");
    },
    toHour(secs) {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;
      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    },
    async initializePage(page) {
      await this.$store.dispatch("players/getPlayers", {
        search: this.search,
        isDisconnect: this.select.id,
        isBot: this.playerSelect.id,
        isSitIn: this.sitSelect.id,
        os: this.os,
        flag: this.flag,
        options: this.options,
        page: page ? page - 1 : this.options.page - 1,
      });
      this.totalPage = this.$store.state.players.all.totalPage;
    },
    filterFlag(flag) {
      this.flag = flag;
      this.initialize();
    },
    addMessage(item) {
      this.curretItem = item;
      this.dialog = true;
    },

    async playerInOutDialog() {
      await this.$store.dispatch("players/getPlayerOut");
      this.playerIOdialog = true;
    },
    async sendMessage() {
      this.messageModel.id = this.curretItem.user.id;
      await this.$store.dispatch("players/sendMessage", this.messageModel);
      this.dialog = false;
    },

    getImage(item) {
      return `@/assets/${item}.png`;
      // return `@/assets/${item}.png`;
    },
    sendSlotGiftDialog(item) {
      this.curretItem = item;
      this.dialog1 = !this.dialog1;
    },
    formatMoney(number) {
      var ab = number.toString().split("");
      var a = "";
      ab.map((b) => {
        a += b;
      });
      // eslint-disable-next-line no-console
      console.log(a);

      return number;

      // return a;
    },
    editItem(item) {
      router.push("/players/detail/" + item.user.id);
    },
    async disconnectItem(item) {
      await this.$store.dispatch("players/disconnectPlayer", item.user.id);
    },
  },
  async mounted() {
    await this.$store.commit("players/getParameters");

    // await this.$store.dispatch("notifications/sendNotification", {
    //   title: this.$t("lotteryTitle", "tr"),
    //   data: { type: 3 },
    //   body: this.$t("lotteryMessage", "tr", { name: "Vue.js" }),
    //   registrationToken: [
    //     "cW8zdyutry8:APA91bGQ6NPCLUbaoWboYQyno8F0HVMBf8KXMkVf8fb-iu5hNu76jC8pUP0JqnZkh4g3kN9EAUN58vtyh50kOy2pjLZ3vEV-7agpSAOLqSP38a5CeIUH8OR5xKEZ0jC4yk3ZH2tsaMV9"
    //   ]
    // });
    this.$store.dispatch("apis/getAllVersions").then(() => {
      this.currentVersion = this.$store.state.apis.versions.find(
        (m) => m.id == "currentVersion"
      ).version;
    });
    // await this.initialize();
  },
};
</script>
